import axios from "axios";
import {
  getSlotIdFromAppointment,
  convertFhirToHintPatient,
  parseFhirBundle,
  findExactNameMatch,
  getCasualName,
  getTelecomInfo,
} from "./resources";
import moment from "moment";
import store from "../store";
import {
  UNAUTHORIZED_ACCESS,
  REQUEST_DOSESPOT_CREDENTIALS,
  FETCH_ACCESS_TOKEN_SUCCESS,
  SET_PATIENT_CREATED,
} from "../actions/actions";
import axiosRetry from "axios-retry";
import { createTask, getAccessToken } from "./asyncSettingsServerRequests";
import { STANDARD_DATE_FORMAT_NO_TIME } from "../consts";

export const sleep = ms => new Promise(resolve => setTimeout(resolve, ms));
let token;

const instance = axios.create({
  baseURL: process.env.REACT_APP_FHIR_ENDPOINT,
  timeout: 10000,
  headers: {
    "Content-Type": "application/fhir+json",
    // Cache: "no-cache",
  },
});

axiosRetry(instance, {
  retries: 1,
  retryCondition: async error => {
    if (error.response && error.response.status === 401) {
      let jwtResponse = await getAccessToken();
      if (jwtResponse && jwtResponse.code < 400) {
        store.dispatch({
          type: FETCH_ACCESS_TOKEN_SUCCESS,
          access_token: jwtResponse.access_token,
        });
        return true;
      } else {
        return false;
      }
    } else {
      return false;
    }
  },
  retryDelay: () => {
    return 100;
  },
});

export const setAsyncRequestAuthorization = newToken => {
  token = newToken;
  instance.interceptors.request.use(config => {
    config.headers.Authorization = "Bearer " + token;
    return config;
  });
};

// const pricesInstance = axios.create({
//   baseURL: process.env.REACT_APP_POKITDOK_ENDPOINT,
//   timeout: 10000,
//   headers: {
//     "Content-Type": "application/json",
//     // Cache: "no-cache",
//     // "Access-Control-Allow-Origin": "*",
//     // "crossorigin": "true",
//   },
// });

export const asyncValidate = (values /*, dispatch */) => {
  return sleep(1000).then(() => {
    // simulate server latency
    if (["foo@foo.com", "bar@bar.com"].includes(values.email)) {
      // eslint-disable-next-line no-throw-literal
      throw { email: "Email already Exists" };
    }
  });
};

export const asyncSubmitToServer = async function(values) {
  const sleep = ms => new Promise(resolve => setTimeout(resolve, ms));
  await sleep(2000);
  // destructuring to create a copy only create shallow copy, thus need to use JSON functions
  let serverResource = JSON.parse(JSON.stringify(values));
  // serverResource.name = humanNameConvertToFhir(serverResource.name);
  window.alert(`You submitted:\n\n${JSON.stringify(serverResource)}`);
};

// export const getPrices = async description => {
//   console.debug('get prices api')
//   let data = {
//     data: {
//       "grant_type": "client_credentials"
//     }
//   };
//   let headers = {
//     headers: {
//       "Authorization": "Basic " + new Buffer(process.env.POKITDOK_CLIENT_ID + ":" + process.env.POKITDOK_CLIENT_SECRET).toString("base64")
//     }
//   };
//   try {
//     let authResponse = await pricesInstance.post("/oauth2/token", data, headers);
//     let accessToken = authResponse.access_token;
//     let params = {
//       params: {
//         cpt_code: "99203",
//         zip_code: "78705",
//       }
//     };
//     let priceResponse = await pricesInstance.get("/api/v4/prices/insurance", params);
//     console.debug(priceResponse);
//     return {
//       // code: appointmentResponse.status,
//       // message: appointmentResponse.statusText,
//     };
//   } catch (error) {
//     return handleError(error);
//   }
// }

export const createSlotAndAppointment = async (slot, appointment) => {
  // return new Promise((resolve, reject) => {
  try {
    let slotResponse = await instance.post("/slot", { slot });
    if (appointment !== null) {
      let appointmentResponse = await instance.post("/appointment", {
        appointment,
      });
      return {
        code: appointmentResponse.status,
        message: appointmentResponse.statusText,
      };
    } else {
      return {
        code: slotResponse.status,
        message: slotResponse.statusText,
      };
    }
  } catch (error) {
    return handleError(error);
  }
};

export const updateSlotAndAppointment = async (slot, appointment) => {
  // return new Promise((resolve, reject) => {
  try {
    let slotResponse = await instance.put(`/slot/${slot.id}`, { slot });
    if (appointment !== null) {
      let appointmentResponse = await instance.put(
        `/appointment/${appointment.id}`,
        { appointment }
      );
      return {
        code: appointmentResponse.status,
        message: appointmentResponse.statusText,
      };
    } else {
      return {
        code: slotResponse.status,
        message: slotResponse.statusText,
      };
    }
  } catch (error) {
    return handleError(error);
  }
};

export const deleteSlotAndAppointment = async appointment => {
  try {
    let appointmentResponse = await instance.put(
      `/appointment/${appointment.id}`,
      { appointment }
    );
    let slotId = getSlotIdFromAppointment(appointment);
    await instance.delete(`/slot/${slotId}`);
    return {
      code: appointmentResponse.status,
      message: appointmentResponse.statusText,
    };
  } catch (error) {
    return handleError(error);
  }
};

export const listGoogleCalendarEvent = async (date, practitionerIds) => {
  try {
    let practitionerIdsQuery = "";
    if (Array.isArray(practitionerIds) && practitionerIds.length) {
      practitionerIds.forEach(id => {
        practitionerIdsQuery = practitionerIdsQuery.concat(
          `&practitionerIds[]=${id}`
        );
      });
    } else {
      return {
        events: [],
        code: 204,
      };
    }
    const startDate = moment(date)
      .startOf("month")
      .subtract(7, "days")
      .utc()
      .format();
    const endDate = moment(date)
      .endOf("month")
      .add(7, "days")
      .utc()
      .format();
    const response = await instance.get(
      `/integrations/google/calendar/events?startDate=${startDate}&endDate=${endDate}${practitionerIdsQuery}`
    );
    return {
      events:
        response.data && Array.isArray(response.data.events)
          ? response.data.events
          : [],
      code: response.status,
    };
  } catch (error) {
    return handleError(error);
  }
};

export const deleteSlot = async slot => {
  try {
    let slotResponse = await instance.delete(`/slot/${slot.id}`);
    return {
      code: slotResponse.status,
      message: slotResponse.statusText,
    };
  } catch (error) {
    return handleError(error);
  }
};

export const createPractitioner = async practitioner => {
  try {
    let practitionerResponse = await instance.post(
      "/3_0_1/practitioner",
      practitioner
    );
    return {
      code: practitionerResponse.status,
      message: practitionerResponse.statusText,
    };
  } catch (error) {
    return handleError(error);
  }
};

export const updatePractitioner = async practitioner => {
  try {
    let practitionerResponse = await instance.put(
      `/3_0_1/practitioner/${practitioner.id}`,
      practitioner
    );
    return {
      code: practitionerResponse.status,
      message: practitionerResponse.statusText,
    };
  } catch (error) {
    return handleError(error);
  }
};

export const createPatient = async (patient, organization) => {
  try {
    let patientResponse = await instance.post("/3_0_1/patient", patient);
    if (organization.hintIntegration && organization.hintIntegration.id) {
      let hintPatient = convertFhirToHintPatient(patient);
      await instance.post("/integrations/hint", {
        data: hintPatient,
        model: "patients",
      });
    }
    return {
      code: patientResponse.status,
      message: patientResponse.statusText,
    };
  } catch (error) {
    return handleError(error);
  }
};

export const createNewPatientAndTask = async (
  patientResource,
  organization
) => {
  try {
    const createPatientResponse = await createPatient(
      patientResource,
      organization
    );
    if (createPatientResponse.code >= 400) {
      window.analytics.track("Error Submitting New Patient");
      return {
        code: createPatientResponse.code,
        error: `Error creating patient: Network error occurred. Please try again or contact us directly.`,
      };
    } else {
      store.dispatch({ type: SET_PATIENT_CREATED, patientCreated: true });
    }
    let task = {
      task: `New patient signed up`,
      comments: `patient signed up at ${window.location.href}`,
      patientId: patientResource.id,
      tags: ["new patient signup"],
      status: "not-started",
      dueDate: moment()
        .local()
        .format(STANDARD_DATE_FORMAT_NO_TIME),
    };
    await createTask(task);

    return createPatientResponse;
  } catch (err) {
    return {
      code: 500,
      error: `Error creating patient: ${err}`,
    };
  }
};

export const updatePatient = async patient => {
  try {
    let patientResponse = await instance.put(
      `/3_0_1/patient/${patient.id}`,
      patient
    );
    return {
      code: patientResponse.status,
      message: patientResponse.statusText,
    };
  } catch (error) {
    return handleError(error);
  }
};

export const getPatient = async patient => {
  try {
    let queryString = `given=${patient.firstName.trim()}&family=${patient.lastName.trim()}`;
    if (patient.dob) {
      queryString += `&birthdate=${patient.dob}`;
    }
    if (patient.email) {
      queryString += `&email=${patient.email.trim()}`;
    }
    if (patient.phoneNumber) {
      queryString += `&phone=${patient.phoneNumber}`;
    }
    let patientResponse = await instance.get(`/3_0_1/patient?${queryString}`);
    let patientResources = parseFhirBundle(patientResponse.data);
    let sortedPatients = findExactNameMatch(
      patientResources,
      patient.firstName,
      patient.lastName
    );
    let numPatients =
      sortedPatients.activePatientResources.length +
      sortedPatients.inactivePatientResources.length;
    return {
      code: patientResponse.status,
      activePatients: sortedPatients.activePatientResources,
      inactivePatients: sortedPatients.inactivePatientResources,
      numPatients: numPatients,
    };
  } catch (error) {
    return handleError(error);
  }
};

export const getPractitioner = async id => {
  try {
    let practitionerResponse = await instance.get(
      `/3_0_1/practitioner?_id=${id}`
    );
    let practitioner = parseFhirBundle(practitionerResponse.data);
    return {
      code: practitionerResponse.status,
      practitioner: practitioner[0],
    };
  } catch (error) {
    return handleError(error);
  }
};

export const createSchedule = async schedule => {
  try {
    let scheduleResponse = await instance.post("/3_0_1/schedule", schedule);
    return {
      code: scheduleResponse.status,
      message: scheduleResponse.statusText,
    };
  } catch (error) {
    return handleError(error);
  }
};

export const createZoomMeeting = async (details, userId, tenant) => {
  try {
    const response = await instance.post("/integration/zoom/meeting", {
      details,
      userId,
      tenant,
    });
    return response;
  } catch (error) {
    if (error) {
      return error;
    }
  }
};

const getDosespotAccessToken = state =>
  state.auth.dosespot && state.auth.dosespot.access_token;

export const getMedicationOptions = async searchTerm => {
  try {
    const state = store.getState();
    let dosespotAccessToken = getDosespotAccessToken(state);
    let medicationOptionsResponse = await instance.post(
      `/dosespot/medications/search`,
      {
        staging: true,
        name: searchTerm,
        dosespot_access_token: dosespotAccessToken,
        patientSearch: true,
      }
    );
    return {
      options: medicationOptionsResponse.data.Items,
      code: medicationOptionsResponse.status,
    };
  } catch (error) {
    return handleDosespotError(error);
  }
};

export const getAllergyOptions = async searchTerm => {
  try {
    const state = store.getState();
    let dosespotAccessToken = getDosespotAccessToken(state);
    let allergyOptionsResponse = await instance.post(
      `/dosespot/allergies/search`,
      {
        staging: true,
        search: searchTerm,
        dosespot_access_token: dosespotAccessToken,
        patientSearch: true,
      }
    );
    return {
      options: allergyOptionsResponse.data.Items,
      code: allergyOptionsResponse.status,
    };
  } catch (error) {
    return handleDosespotError(error);
  }
};

export const getFhirQuestionnaire = async (questionnaire, patientId) => {
  try {
    let id = questionnaire.id;
    // console.debug("questionnaireID", id);
    // console.debug("patientId", patientId);
    let questionnaireResponse = await instance.get(
      `/3_0_1/questionnaire/${id}`
    );
    // let questionnaireResource = parseFhirBundle(questionnaireResponse.data);
    let answers;
    if (patientId) {
      let answerResponse = await instance.get(
        `/3_0_1/questionnaireResponse?questionnaire=${id}&patient=${patientId}`
      );
      answers = parseFhirBundle(answerResponse.data);
    }
    return {
      code: questionnaireResponse.status,
      questionnaire: questionnaireResponse.data,
      answers: answers ? answers[0] : null,
    };
  } catch (error) {
    return handleError(error);
  }
};

export const createQuestionnaireResponse = async resource => {
  try {
    let answersResponse = await instance.post(
      "/3_0_1/questionnaireResponse",
      resource
    );
    return {
      code: answersResponse.status,
      message: answersResponse.statusText,
    };
  } catch (error) {
    return handleError(error);
  }
};

export const updateQuestionnaireResponse = async resource => {
  try {
    let answersResponse = await instance.put(
      "/3_0_1/questionnaireResponse",
      resource
    );
    return {
      code: answersResponse.status,
      message: answersResponse.statusText,
    };
  } catch (error) {
    return handleError(error);
  }
};

export const sendEmailAppointment = async (
  newPatient,
  appointment,
  apptMeta,
  organization,
  patient,
  event,
  availability,
  patientNotification = true
) => {
  let name = getCasualName(patient.name);
  let telecom = getTelecomInfo(patient.telecom);
  if (
    !telecom.email ||
    !organization.replyEmailVerified ||
    !organization.fromEmail ||
    !organization.replyEmail ||
    !organization.emailName
  ) {
    return null;
  }
  const eventTime =
    organization.settings && organization.settings.noAppointmentEmailTime
      ? moment(appointment.start).format("ll")
      : availability && availability.timezone
      ? moment(appointment.start)
          .tz(availability.timezone)
          .format("lll z")
      : moment(appointment.start).format("lll");
  const location =
    apptMeta && apptMeta.videoVisit && apptMeta.videoUrl
      ? apptMeta.videoUrl
      : organization.settings &&
        organization.settings.noAppointmentEmailLocation
      ? null
      : `${organization.addressLine1}${
          organization.addressLine2 ? `, ${organization.addressLine2}` : ""
        }, ${organization.city}, ${organization.state}, ${
          organization.zipcode
        }`;
  const eventEmail = {
    id: appointment.id,
    version: 1,
    subject: `New Appointment At ${organization.name}`,
    location: location,
    description: `${appointment.description}${
      apptMeta && apptMeta.videoVisit && apptMeta.videoUrl
        ? `\n\nLocation: This is a video visit.\n\nYou can join this visit from your computer, tablet, or smartphone by clicking this link: <a href=${apptMeta.videoUrl} target="_blank" rel="noopener noreferrer">${apptMeta.videoUrl}</a>`
        : ""
    }`,
    replyName: organization.emailName,
    replyEmail: organization.replyEmail,
    toEmail: telecom.email,
    toName: name,
    fromEmail: organization.fromEmail,
    fromName: organization.emailName,
    plainContent: `Hi ${name},\n\nYou have been scheduled for an appointment at ${
      organization.name
    } for ${eventTime}. Please contact us if you have any questions.${
      apptMeta && apptMeta.videoVisit && apptMeta.videoUrl
        ? `\n\nLocation: This is a video visit.\n\nYou can join this visit from your computer, tablet, or smartphone by clicking this link: <a href=${apptMeta.videoUrl} target="_blank" rel="noopener noreferrer">${apptMeta.videoUrl}</a>`
        : ""
    }`,
    htmlContent: `${event.patientEmail ? event.patientEmail : ""}${
      apptMeta && apptMeta.videoVisit && apptMeta.videoUrl
        ? `<br/><br/>Location: This is a video visit.<br/><br/>You can join this visit from your computer, tablet, or smartphone by clicking this link: <a href=${apptMeta.videoUrl} target="_blank" rel="noopener noreferrer">${apptMeta.videoUrl}</a>`
        : ""
    }`,
    startUtcTime: appointment.start,
    endUtcTime: appointment.end,
    patientNotification: patientNotification,
  };
  try {
    let emailResponse = await instance.post(`/emails/email`, eventEmail);
    return {
      code: emailResponse.status,
    };
  } catch (error) {
    return handleError(error);
  }
};

export const auth = async ({ email, password }) => {
  return new Promise((resolve, reject) =>
    setTimeout(() => {
      if (email === "" || password === "") {
        return reject("That email and password combination is incorrect");
      } else if (email === "z" && password === "z") {
        return reject(
          "There was an error connecting to the server. Please try again."
        );
      }
      resolve();
    }, 1000)
  );
};

export const getSlotsForPractitioner = async (practitionerId, date) => {
  try {
    const scheduleResponse = await instance.get(
      `/3_0_1/schedule?actor=Practitioner/${practitionerId}`
    );
    const schedules = parseFhirBundle(scheduleResponse.data);
    const startDate = moment(date)
      .startOf("month")
      .subtract(7, "days")
      .format("YYYY-MM-DD");
    const endDate = moment(date)
      .endOf("month")
      .add(7, "days")
      .format("YYYY-MM-DD");
    const slotResponse = await instance.get(
      `/3_0_1/slot?schedule=Schedule/${schedules[0].id}&start=ge${startDate}&start=le${endDate}`
    );
    // get slot data from slot Bundle
    const slots = parseFhirBundle(slotResponse.data);
    return {
      code: slotResponse.status,
      message: slotResponse.statusText,
      slots: slots,
      scheduleId: schedules[0].id,
    };
  } catch (error) {
    return handleError(error);
  }
};

export const getPractitionerSchedule = async practitionerId => {
  try {
    const scheduleResponse = await instance.get(
      `/3_0_1/schedule?actor=Practitioner/${practitionerId}`
    );
    const schedules = parseFhirBundle(scheduleResponse.data);
    const scheduleId =
      Array.isArray(schedules) && schedules[0] && schedules[0].id;
    return {
      code: scheduleResponse.status,
      message: scheduleResponse.statusText,
      scheduleId: scheduleId,
    };
  } catch (error) {
    return handleError(error);
  }
};

export const submitQuestionnaireResponses = async (
  questionnaireResponses,
  formBundle,
  patient,
  newPatient
) => {
  try {
    let response;
    const organization = store.getState().settings.organization;
    const patientCreated = store.getState().patients.patientCreated;
    if (newPatient && !patientCreated) {
      const patientResponse = await createNewPatientAndTask(
        patient,
        organization
      );
      if (patientResponse.code >= 400) {
        let message =
          patientResponse && typeof patientResponse.message === "string"
            ? patientResponse.message
            : "Error submitting form";
        response = {
          statusCode: 400,
          error: message,
        };
        return response;
      }
    }
    if (
      !(Array.isArray(questionnaireResponses) && questionnaireResponses.length)
    ) {
      response = {
        statusCode: 400,
        error: "No forms to submit",
      };
      return response;
    }
    // if (!this.props.savedQuestionnaireAnswers) {
    let answerResponse;
    for (let index = 0; index < questionnaireResponses.length; index += 1) {
      answerResponse = await createQuestionnaireResponse(
        questionnaireResponses[index]
      );
    }
    // } else {
    //   answerResponse = await updateQuestionnaireResponse(resource);
    // }
    if (answerResponse && answerResponse.code < 400) {
      response = {
        statusCode: 200,
      };
      const formTitle =
        questionnaireResponses.length === 1 &&
        questionnaireResponses[0].questionnaire.display
          ? questionnaireResponses[0].questionnaire.display
          : formBundle.title;
      let task = {
        task: `${formTitle}: submitted form`,
        patientId: patient.id,
        attachmentResourceType: "QuestionnaireResponse",
        attachmentResources: [questionnaireResponses[0].id],
        tags: ["form submission"],
        status: "not-started",
        dueDate: moment()
          .local()
          .format(STANDARD_DATE_FORMAT_NO_TIME),
      };
      createTask(task);
      const organization = store.getState().settings.organization;
      window.analytics.track("New questionnaire response submitted", {
        patient: patient.id,
        tenant: organization._id,
      });
      // await handleSubmit();
    } else {
      let message =
        answerResponse && typeof answerResponse.message === "string"
          ? answerResponse.message
          : "Error submitting form";
      response = {
        statusCode: 400,
        error: message,
      };
      window.analytics.track("Error submitting questionnaire", {
        patient: this.props.patient.id,
      });
    }
    return response;
  } catch (error) {
    return handleError(error);
  }
};

export const getAvailableTimes = async (
  practitionerId,
  date,
  eventId,
  timezone
) => {
  try {
    const response = await instance.get(`/availability/${practitionerId}`, {
      params: {
        date,
        eventId,
        timezone,
      },
    });
    return {
      data: response.data,
      code: response.status,
    };
  } catch (error) {
    return handleError(error);
  }
};

export const getPractitioners = async practitionerIds => {
  try {
    const response = await instance.get("/user", {
      params: {
        practitionerIds: practitionerIds,
        scheduling: true,
      },
    });
    return {
      code: response.status,
      data: response.data,
    };
  } catch (error) {
    return handleError(error);
  }
};

const handleError = error => {
  if (error.response) {
    let errorResponse = error.response; // JSON.parse(error);
    let errorMessage = "";
    let errorCode = "";
    if (
      errorResponse.data &&
      errorResponse.data.issue &&
      errorResponse.data.issue[0].diagnostics
    ) {
      errorCode = errorMessage = errorResponse.data.issue[0].code;
      errorMessage = errorResponse.data.issue[0].diagnostics;
    } else if (errorResponse.data) {
      errorMessage = errorResponse.data;
    } else if (errorResponse.statusText) {
      errorMessage = errorResponse.statusText;
    } else {
      errorMessage = "Error";
    }
    if (errorResponse.status === 401) {
      store.dispatch({ type: UNAUTHORIZED_ACCESS });
    }
    return {
      code: errorResponse.status,
      message: errorMessage,
      error: errorMessage,
      errorCode: errorCode,
    };
  } else {
    return {
      code: 400,
      message: error,
      error: error,
    };
  }
};

const handleDosespotError = error => {
  if (
    error.response &&
    error.response.data &&
    error.response.data.dosespotStatus === 401
  ) {
    store.dispatch({ type: REQUEST_DOSESPOT_CREDENTIALS });
  }
  if (error.response) {
    let description = error.response.data && error.response.data.description;
    return {
      code: error.response.status,
      error: description,
      message: description,
    };
  } else {
    return {
      code: 500,
      error: "Server Error",
      message: "Server Error",
    };
  }
};
